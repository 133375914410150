import AES from 'crypto-js/sha256'
import encode from 'crypto-js/enc-utf8'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import axios from 'axios'
import nameOptions from '@/data/nameGenerator.json'
import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'
import db from './init'
import solFunctions from '@/db/solana'
import ufcStrikeNFTs from '@/cadence/scripts/ufc_strike_nfts.cdc'

const _ = require('lodash')

export default {
  encryptData(value) {
    const hashKey = process.env.VUE_APP_HASH_secret
    const encryptData = AES.encrypt(value, hashKey,)
      .toString()

    return encryptData
  },
  decryptData(value) {
    const hashKey = process.env.VUE_APP_HASH_secret
    const decryptData = AES.decrypt(value, hashKey,)
      .toString(encode.Utf8)

    return decryptData
  },
  async fileUpload(dir, filename, file, base64) {
    let imageURL = ''
    const storage = firebase.storage()
    if (base64) {
      storage
        .ref(`${dir}/${filename}`)
        .putString(file, 'base64', {contentType: 'image/png'})
        .then(() => {
          console.log('Uploaded a base64 string!')
        })
    }
    const storageRef = storage.ref()
      .child(`${dir}/${filename}`)
    const task = storageRef.put(file)

    // update progress bar
    await task.on('state_changed', snapshot => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log(`Upload is ${progress} % done`)
      // eslint-disable-next-line default-case
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused')
          break
        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running')
          break
        default:
      }
    }, error => {
      console.log(error)
    }, async () => {
      // Handle successful uploads on complete
      await task.snapshot.ref.getDownloadURL()
        .then(downloadURL => {
          console.log('File available at', downloadURL)
          imageURL = downloadURL

          return imageURL
        })
    },)
  },
  createProfile(userUID) {
    console.log('createProfile')
    if (userUID !== '') {
      db.collection('profiles')
        .doc(userUID)
        .set({
          userUID,
          lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
          sessions: firebase.firestore.FieldValue.increment(1), // Number of logins
          rank: 0, // Belt
          xp: 0, // Experience points
          totalPoints: 0, // Fantasy Points
          lastPoints: 0, // Fantasy Points
          eventsEntered: 0, // tournament events
          arcadePlayed: 0, // total arcade games played
          achievements: [], // Torno won, unlocked
          onBoardered: false, // issue one random player and 1M KombatCoins
          starDustPlayerId: '',
          silverKombatCoins: 0, // Stored total refer to SD for live transactions
          silverKombatCoinsLastTrans: 0, // Last Transaction
          freeSilverKombatCoins: 0, // number of free kombatCoins issued
          goldKombatCoins: 0, // Stored total refer to SD for live transactions
          goldKombatCoinsLastTrans: 0, // Last Transaction
          solanaAddress: '', // Solana internal game Wallet Address for SPL
          teams: [], // Fantasy Teams
          freeFighterPacks: 0, // number of random fighter packs issued
          accountType: 0, // Account type 0 free, 1 paid
          country: 0, // User origin  0 free, 1 paid
          locale: '', // used for timezone and formatting
          age: 0, // Birthdate
          bio: '', // User free text profile bio
        })
        .then(() => {
          console.log('Profile successfully created!')
        })
        .catch(error => {
          console.error('Error writing profile: ', error)
        })
    }
  },
  async readUsersProfile(userUID) {
    let profileData = {}
    await db.collection('profiles')
      .doc(userUID)
      .get()
      .then(doc => {
        profileData = doc.data()
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })

    return profileData
  },
  async updateUsersProfile(user, fields) {
    const profileData = fields
    profileData.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('profiles')
      .doc(user.uid)
      .update(profileData)
      .then(profile => profile)
      .catch(error => {
        console.log(error)
      })
  },
  async addSKC(userUID, amount, addOrSubtract) {
    this.readUsersProfile(userUID)
      .then(async doc => {
        const skc = doc.silverKombatCoins
        let newSkc = Number(skc.toString()
          .replace(/,/g, ''))
        let amountNumber = Number(amount.toString()
          .replace(/,/g, ''))

        if (addOrSubtract === 'add') {
          newSkc = skc + amountNumber
        } else if (addOrSubtract === 'subtract') {
          newSkc = skc - amountNumber
        }
        const profileData = {
          silverKombatCoins: Number(newSkc.toString()
            .replace(/,/g, '')),
          silverKombatCoinsLastTrans: Number(amountNumber.toString()
            .replace(/,/g, '')),
          silverKombatCoinsLastTransType: addOrSubtract,
        }
        profileData.modified = firebase.firestore.FieldValue.serverTimestamp()
        let logData = profileData
        logData.user = userUID
        await db.collection('skcLog')
          .add(logData)
          .catch(error => {
            console.log(error)
          })
        await db.collection('profiles')
          .doc(userUID)
          .update(profileData)
          .then(profile => profile)
          .catch(error => {
            console.log(error)
          })
      })
  },
  randomWord(arrayInput) {
    const word = arrayInput[Math.floor(Math.random() * arrayInput.length)]

    return word.charAt(0)
      .toUpperCase() + word.slice(1)
  },

  loadLogos(r) {
    const logos = []
    r.keys()
      .forEach(key => (logos.push(r(key))))
    return this.shuffleLogos(logos)
  },
  shuffleLogos(logoList) {
    const array = logoList
    array.sort(() => Math.random() - 0.5) // rough shuffle not truly random

    return array
  },
  async createTeam(userUID) {
    if (userUID !== '') {
      let names = nameOptions

      const teamName = `${this.randomWord(names.adjectives)} ${this.randomWord(names.nouns)}`
      const logos = this.loadLogos(require.context('@/assets/images/team-logos/', true, /\.jpg$/))
      const logo = logos[Math.floor(Math.random() * logos.length)]

      await db.collection('teams')
        .doc(userUID)
        .set({
          owner: userUID,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          name: teamName,
          desc: '',
          logo: logo, // Image URL
          color: '', // Team primary color
          captain: '', // Fighter allocated as captain
          advisor: [], // Team Advisor
          training: [], // Team Advisor
          leagues: [], // Participating leagues
          totalPoints: 0, // Fantasy Points to compare
          lastPoints: 0, // Fantasy Points
          teamValue: 0, // Total value of team based on all teams fighters
          activeFighters: [], // Fighters in Team
          scoutedFighters: [], // Fighters added from scouting
          inactiveFighters: [], // Hold for in active Fighters in Team
          followedFighters: [], // Fighters user is following
        })
        .then(() => {
          console.log('Team successfully created')

          return true
        })
        .catch(error => {
          console.error('Error creating team: ', error)

          return false
        })
    }
  },
  async getTeam(userUID) {
    let teamData = {}
    if (userUID !== '') {
      await db.collection('teams')
        .doc(userUID)
        .get()
        .then(doc => {
          teamData = doc.data()
          if (teamData) {
            teamData.id = doc.id
          }
        })
        .catch(error => {
          console.log('Error getting team: ', error)
        })
    }

    return teamData
  },
  async updateTeam(userUID, fields) {
    const teamData = fields
    if (userUID !== '') {
      teamData.modified = firebase.firestore.FieldValue.serverTimestamp()
      await db.collection('teams')
        .doc(userUID)
        .update(teamData)
        .then(() => {
          console.log('Team updated')
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      this.$router.push('/403')
    }
  },
  async getAdvisors() {
    return db.collection('advisors')
      .where('cardType', '==', 'MFC Coach')
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async addAdvisors(coachslug, fields) {
    return db.collection('advisors')
      .doc(coachslug)
      .set(fields)
      .then(() => {
        console.log('Coach successfully created')

        return true
      })
      .catch(error => {
        console.error('Error creating team: ', error)

        return false
      })
  },
  async getAllFighters(filters) {
    let qfilters = filters
    if (!qfilters) {
      qfilters = ['pending-update', 'active']
    }

    return db.collection('fighters')
      .where('status', 'in', qfilters)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getRandomFighter() {
    return db.collection('fighters')
      .where('rank', '=', 0)
      .get()
      .then(querySnapshot => {
        const array = querySnapshot.docs
        const fighter = array[Math.floor(Math.random() * array.length)]

        return (fighter.data().fighter_id)
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getRandomRankedFighter() {
    return db.collection('fighters')
      .where('rank', '>', 1)

      .get()
      .then(querySnapshot => {
        const fighterData = []
        let fighter = {}
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (!data.xfactor) {
            data.id = doc.id
            fighterData.push(data)
          }
        })

        fighter = fighterData[Math.floor(Math.random() * fighterData.length)]

        return (fighter.fighter_id)
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getAllTeamFighters(userUID) {
    return db.collection('mintedFighters')
      .where('current_team', '==', userUID)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getAllTeamMommaFighters(userUID) {
    return db.collection('mommaFighters')
      .where('owner', '==', userUID)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  recruitRandomMFCFighter() {
    let fighterList = []
    return db.collection('mommaFighters')
      .where('published', '==', true)
      .where('owner', '==', 'vP11rmofXaO82MnfpG1imCDKj9o1') // momma account
      .where('xp', '<', 40)
      .where('scoutCode', '==', '')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(mfc => {
          const mfcData = mfc.data()
          mfcData.id = mfc.id
          fighterList.push(mfcData)
        })
        const selectedMFCfighter = fighterList[Math.floor(Math.random() * fighterList.length)]

        return selectedMFCfighter
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async syncFighterBaseMintedTemplate(fighterData) {
    console.log('sync DB functions')
    const fdata = fighterData
    db.collection('mintedFighters')
      .where('token_template_id', '==', fdata.token_template_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          if (doc.id) {
            if (fdata.status === 'pending-update') {
              fdata.status = 'active'
            }
            this.updateMintedFighter(doc.id, fdata)
          }
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async createLeague(userUID, fields) {
    return db.collection('leagues')
      .add({
        createdBy: userUID,
        admins: [userUID], // league admin user ideas
        created: firebase.firestore.FieldValue.serverTimestamp(),
        maxPlayers: 100,
        name: fields.name,
        desc: '',
        status: 'active',
        logo: fields.logo, // Image URL
        visibility: fields.visibility, // private leagues are invite only
        inviteCode: Math.random()
          .toString(16)
          .slice(8), // Code to share to others to join
        totalPoints: 0, // Fantasy Points to compare
        lastPoints: 0, // Fantasy Points
        leagueValue: 0, // value of league based on all teams fighters
        achievements: [], // League Achievements
        leagueType: 0, // League Account type 0 default
        partner: fields?.partner || null, // verified partner
        embed1: fields?.embed1 || null, // youtube
        embed2: fields?.embed1 || null, // spotify other
        twitter: fields?.twitter || null, // twitter handler
      })
      .then(data => data)
      .catch(error => {
        console.error('Error writing league: ', error)
      })
  },
  async updateLeague(userUID, LeagueID, fields) {
    if (userUID === fields.createdBy) {
      const leagueData = fields
      leagueData.modified = firebase.firestore.FieldValue.serverTimestamp()
      await db.collection('leagues')
        .doc(LeagueID)
        .update(leagueData)
        .then(() => {
          console.log('League updated')
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      console.log('Only owners can edit leagues')
    }
  },

  async removefromLeague(leagueId, userId) {
    await this.getTeam(userId)
      .then(async teamData => {
        teamData.leagues.splice(teamData.leagues.indexOf(leagueId), 1)
        const fields = {leagues: teamData.leagues}
        await this.updateTeam(userId, fields)
      })

    return 'done'
  },
  async getLeagueTeams(leagueId) {
    return db.collection('teams')
      .where('leagues', 'array-contains', leagueId)
      .get()
      .then(querySnapshot => {
        const teams = []
        querySnapshot.forEach(doc => {
          const team = doc.data()
          team.id = doc.id
          teams.push(team)
        })

        return teams
      })
      .catch(error => {
        console.log('Error getting leagues: ', error)
      })
  },
  async getMyLeagues(leagueId) {
    return db.collection('leagues')
      .doc(leagueId)
      .get()
      .then(doc => doc)
      .catch(error => {
        console.log('Error getting leagues: ', error)
      })
  },
  async getLeagues(status) {
    return db.collection('leagues')
      .where('status', '==', status)
      .get()
      .then(querySnapshot => {
        const leagues = []
        querySnapshot.forEach(doc => {
          const league = doc.data()
          league.id = doc.id
          leagues.push(league)
        })

        return leagues
      })
      .catch(error => {
        console.log('Error getting leagues: ', error)
      })
  },
  async getBaseFighter(fighterId) {
    let fighterData = {}
    await db.collection('fighters')
      .where('fighter_id', '==', fighterId)
      .get()
      .then(querySnapshot => {
        const fighter = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          fighter.push(data)
        })
        fighterData = fighter[0]  // eslint-disable-line

        return fighterData
      })
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })

    return fighterData
  },

  async getFighterFromName(fighterName) {
    let fighterData = {}
    await db.collection('fighters')
      .where('name', '==', fighterName)
      .get()
      .then(querySnapshot => {
        const fighter = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          fighter.push(data)
        })
        fighterData = fighter[0]  // eslint-disable-line

        return fighterData
      })
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })

    return fighterData
  },
  async getBaseFighterSlug(fighterSlug, srSlug = false) {
    let fighterData = {}
    const fighter = []
    await db.collection('fighters')
      .where('slug', '==', fighterSlug)
      .get()
      .then(async querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          fighter.push(data)
        })
        fighterData = fighter[0]  // eslint-disable-line
        if (!fighterData) {
          let slugName = 'ufc_slug'
          if (srSlug) {
            slugName = 'sr_slug'
          }
          await db.collection('fighters')
            .where(slugName, '==', fighterSlug)
            .get()
            .then(querySnapshot1 => {
              querySnapshot1.forEach(doc => {
                const data = doc.data()
                data.id = doc.id
                fighter.push(data)
              })
              // eslint-disable-next-line prefer-destructuring
              fighterData = fighter[0]
            })
        }

        return fighterData
      })
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })

    return fighterData
  },
  async updateImage(fighterData) {
    const api = axios.create({
      baseURL: `${process.env.VUE_APP_VM_BASE}/`,
      timeout: 10 * 60 * 1000, // whatever time you want
    })
    if (fighterData.head_shot_url) {
      api.get(`?func=toonify&image=${encodeURIComponent(fighterData.head_shot_url)}&slug=${fighterData.slug}&shot=head`)
        .then(() => {
          console.log('Process image')
        })
    } else {
      console.log('no head shot url')
    }
    if (fighterData.full_body_url) {
      api.get(`?func=toonify&image=${encodeURIComponent(fighterData.full_body_url)}&slug=${fighterData.slug}&shot=body`)
        .then(() => {
          console.log('Process image')
        })
    } else {
      console.log('no body shot url')
    }
  },
  async updateMissingImages() {
    return db.collection('fighters')
      .where('full_body_url', '==', '')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const fdata = doc.data()
          fdata.status = 'active'
          console.log(fdata.slug)
          this.updateImage(fdata)
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getMintedFighter(UID) {
    let fighterData = {}
    await db.collection('mintedFighters')
      .doc(UID)
      .get()
      .then(doc => {
        fighterData = doc.data()
      })
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })

    return fighterData
  },
  async getMintedFighterFighterId(id, owner) {

    return db.collection('mintedFighters')
      .where('fighter_id', '==', id)
      .where('owner', '==', owner)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })
  },
  async getAllMintedFighters() {
    return db.collection('mintedFighters')
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting fighter data: ', error)
      })
  },
  async getAllMintedFighters4Sale(filters) {
    let qfilters = filters
    if (!qfilters) {
      qfilters = ['pending-update', 'active']
    }

    return db.collection('mintedFighters')
      .where('selling', '==', true)
      .where('status', 'in', qfilters)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async saveMintedFighter(fighter, ownerUid) {
    const fighterProps = fighter
    fighterProps.owner = ownerUid
    fighterProps.current_team = ownerUid
    fighterProps.purchase_price = fighter.purchase_price || fighter.current_price
    fighterProps.current_price = fighter.current_price || 0
    fighterProps.created = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('mintedFighters')
      .add(fighterProps)
      .then(fighterRecord => {
        this.NFTstatus = 'NFT process complete!'

        return fighterRecord.id
      })
      .catch(error => {
        console.error('Error writing minted fighter: ', error)
      })
  },
  async updateFighter(slug, fields) {
    await db.collection('fighters')
      .doc(slug)
      .update(fields)
      .then(fighterData => fighterData)
      .catch(error => {
        console.log(error)

        return error
      })
  },
  async updateMintedFighter(uid, fighterFields) {
    const fields = fighterFields
    await db.collection('mintedFighters')
      .doc(uid)
      .get()
      .then(doc => {
        const fighterData = doc.data()
        fields.last_updated = firebase.firestore.FieldValue.serverTimestamp()
        fields.current_price = fighterFields?.current_price || fighterData.current_price
        fields.purchase_price = fighterFields?.purchase_price || fighterData.purchase_price || fields.current_price
        fields.cost = fighterFields?.purchase_price || fighterData.cost || fields.cost

        if (fields.training) {
          delete fields.training
        }
        if (fighterFields.head_shot_url) {
          const fbimage = `https://storage.googleapis.com/fk-public/body-${fighterFields.slug}.png`
          const himage = `https://storage.googleapis.com/fk-public/head-${fighterFields.slug}.png`
          fields.head_shot_url = himage
          fields.full_body_url = fbimage
          fields.image = fbimage
        }
        db.collection('mintedFighters')
          .doc(uid)
          .update(fields)
          .then(data => data)
          .catch(error => {
            console.log(error)

            return error
          })
      })
      .catch(error => {
        console.log(error)

        return error
      })
  },
  async checkAdmin(user) {
    await this.readUsersProfile(user.uid)
      .then(data => {
        if (data.admin) {
          return true
        }

        return false
      })
      .catch(error => {
        console.log(error)

        return false
      })
  },
  async getUFCEvent(id) {
    let eventData = []
    await db.collection('ufcEvents')
      .doc(id)
      .get()
      .then(eventResp => {
        eventData = eventResp.data()
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async currentUFCEvent() {
    let eventData = []
    await db.collection('ufcEvents')
      .where('status', '==', 'Final')
      .get()
      .then(eventResp => {
        eventData = eventResp.docs[0].data()
        eventData.id = eventResp.docs[0].id
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async searchUFCEvent(searchField, id) {
    const eventData = []
    await db.collection('ufcEvents')
      .where(searchField, '==', id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          eventData.push(data)
        })
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async allUFCEvents(past = false, limit = 60) {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() - 2)
    const eventData = []
    await db.collection('ufcEvents')
      .orderBy('date', 'desc')
      .limit(limit)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (data?.hide !== true) {
            data.id = doc.id
            data.event_type = 'MMA Fight'
            data.prize_pool = '3200000' // default prize pool
            data.link = `/tournaments/ufc-fight-night/${data.id}`

            if (!data.date) {
              data.date = new Date(data.id)
            }
            data.timestamp = new Date(data.date).getTime()
            if (Date.parse(data.date)) {
              if (past) {
                if (new Date(data.date) <= tomorrow) {
                  if (!data.status) {
                    data.status = 'past'
                  }
                  if (!data.hide) {
                    eventData.push(data)
                  }
                }
              } else if (new Date(data.date) > tomorrow) {
                if (!data.status) {
                  data.status = 'upcoming'
                }
                if (!data.hide) {
                  eventData.push(data)
                }
              }
            }
          }
        })
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async getMFCEvent(id) {
    let eventData = []
    await db.collection('mfcEvents')
      .doc(id)
      .get()
      .then(eventResp => {
        eventData = eventResp.data()
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async nextUFCeventFighters() {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() - 0.5)
    const eventData = []
    await db.collection('ufcEvents')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          if (!data.date) {
            data.date = new Date(data.id)
          }
          data.timestamp = new Date(data.date).getTime()
          if (Date.parse(data.date)) {
            if (new Date(data.date) <= tomorrow) {
              if (!data.status) {
                data.status = 'past'
              }
              eventData.push(data)
            } else if (new Date(data.date) > tomorrow) {
              if (!data.status) {
                data.status = 'upcoming'
              }
              eventData.push(data)
            }
          }
        })
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },
  async updateUFCEvent(id, ufields) {
    const fields = ufields
    fields.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('ufcEvents')
      .doc(id)
      .update(fields)
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })
  },
  async updateAdmin(ufields) {
    const fields = ufields
    await db.collection('admin')
      .doc('A000000')
      .update(fields)
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })
  },

  async allMFCEvents(past = false) {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() - 2)
    const eventData = []
    await db.collection('mfcEvents')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          data.event_type = 'MoMMA Fight Night'
          data.link = `/tournaments/momma-fight-night/${data.id}`
          if (!data.date) {
            data.date = new Date(data.id)
          }
          const mfcDate = data.date.toDate()
          data.date = mfcDate
          data.timestamp = mfcDate.getTime()
          if (data.date) {
            if (past) {
              if (mfcDate <= tomorrow) {
                if (!data.status) {
                  data.status = 'past'
                }
                if (!data.hide) {
                  eventData.push(data)
                }
              }
            } else if (mfcDate > tomorrow) {
              if (!data.status) {
                data.status = 'upcoming'
              }
              if (!data.hide) {
                eventData.push(data)
              }
            }
          }
        })
      })
      .catch(error => {
        console.log('Error getting eventData: ', error)
      })

    return eventData
  },

  async saveMFCEvent(userId, eventId, fightSlots) {
    const id = `${userId}-${eventId}`
    let data = {
      userId,
      eventId,
      fightSlots,
      opponentSet: false,
      modified: firebase.firestore.FieldValue.serverTimestamp()
    }
    return db.collection('mfcEventFights')
      .doc(id)
      .set(data)

  },

  async updateMFCEvent(userId, eventId, fields) {
    const id = `${userId}-${eventId}`
    return db.collection('mfcEventFights')
      .doc(id)
      .update(fields)

  },

  async saveMFCEventOpponent(eventId, fightSlot, fighter) {
    console.log('saveMFCEventOpponent')
    console.log(eventId)
    console.log(fightSlot, fighter)
    let data = {}
    await db.collection('mfcEventFights')
      .doc(eventId)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.exists) {
          const data = querySnapshot.data()
          data.id = querySnapshot.data().id
          if (fighter.id) {
            data.fightSlots[fightSlot].opponent = fighter
          }
          let nData = {
            fightSlots: data.fightSlots,
            opponentSet: true,
            modified: firebase.firestore.FieldValue.serverTimestamp(),
            eventId: data.eventId,
            userId: data.userId
          }
          console.log(nData)

          return db.collection('mfcEventFights')
            .doc(eventId)
            .set(nData)

        }
      })
      .catch(error => {
        console.log('Error getting currency data: ', error)
        return data
      })
    return data
  },

  allMFCEventFights() {
    return db.collection('mfcEventFights')
      .get()
      .then(querySnapshot => {
        return querySnapshot
      })
  },
  getMFCEventFight(userId, eventId) {
    const id = `${userId}-${eventId}`
    return db.collection('mfcEventFights')
      .doc(id)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data()
        if (data) {
          data.id = querySnapshot.data().id
        }
        return data
      })
      .catch(error => {
        console.log('Error getting currency data: ', error)
      })

  },
  async getMFCEventFightOpponent(userId, eventId) {
    const id = `${userId}-${eventId}`
    //opponentSet
    await db.collection('mfcEventFights')
      .where('eventId', '==', eventId)
      .get()
      .then(querySnapshot => querySnapshot)
    return db.collection('mfcEventFights')
      .doc(id)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data()
        return data
      })
      .catch(error => {
        console.log('Error getting currency data: ', error)
      })

  },
  async getCurrencies() {
    return db.collection('currencies')
      .get()
      .then(querySnapshot => {
        const currencyList = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          currencyList.push(data)
        })

        return currencyList
      })
      .catch(error => {
        console.log('Error getting currency data: ', error)
      })
  },
  async checkBalance(user, currencyField, cost) {
    let profileData = {}
    const balanceData = {
      balance: 0,
      sufficientBalance: false,
    }
    await db.collection('profiles')
      .doc(user.uid)
      .get()
      .then(doc => {
        profileData = doc.data()

        console.log('db balance check')
        if (cost === undefined) {
          balanceData.sufficientBalance = true
        } else {
          balanceData.balance = profileData[currencyField] || 0
          if (cost > balanceData.balance) {
            balanceData.sufficientBalance = true
          }
        }
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })

    return balanceData
  },
  async completeCoinTransaction(user, currencyField, balance, cost) {
    const afterDeduct = balance - cost
    const fields = {}
    fields.modified = firebase.firestore.FieldValue.serverTimestamp()
    fields[currencyField] = afterDeduct
    await db.collection('profiles')
      .doc(user.uid)
      .update(fields)
      .then(profile => profile)
      .catch(error => {
        console.log(error)
      })
  },
  async savePickem(userUID, eventID, picks, date, status, version) {
    const userEventId = `${userUID}-${eventID}`
    let saved = false
    if (userUID !== '') {
      const data = []
      data.owner = userUID
      data.eventID = eventID
      if (status === 'Upcoming') {
        const modified = firebase.firestore.FieldValue.serverTimestamp()
        await db.collection('ufcPickEms')
          .doc(userEventId)
          .set({
            data: picks,
            status: 'next',
            modified,
            owner: data.owner,
            eventID: data.eventID,
            version
          }, {merge: true})
          .then(() => {
            saved = false
          })
          .catch(error => {
            console.error('Error saving picks: ', error)
            saved = false
          })
      } else if (status === 'Live') {
        console.log('Event already started')
        const modified = firebase.firestore.FieldValue.serverTimestamp()
        await db.collection('ufcPickEms')
          .doc(userEventId)
          .set({
            status: 'live',
            modified,
            owner: data.owner,
            eventID: data.eventID,
          })
          .then(() => {
            saved = false
          })
          .catch(error => {
            console.error('Error saving picks: ', error)
            saved = false
          })
        saved = false
      } else {
        saved = false
      }
    }

    return saved
  },
  async getPicks(userUID, eventID) {
    const userEventId = `${userUID}-${eventID}`
    return db.collection('ufcPickEms')
      .doc(userEventId)
      .get()
      .then(doc => doc.data())
      .catch(error => {
        console.log('Error getting data: ', error)
      })
  },
  async getAllPicks() {
    const picks = []
    await db.collection('ufcPickEms')
      .get()
      .then(querySnapshot => {
        let data = {}
        console.log(querySnapshot)
        querySnapshot.forEach(doc => {
          console.log(data)
          data = doc.data()
          data.id = doc.id
          picks.push(data)
        })
      })

    return picks
  },

  async getAllEventPicks(eventID) {
    const picks = []
    await db.collection('ufcPickEms')
      .where('eventID', '==', eventID)
      .get()
      .then(querySnapshot => {
        let data = {}
        console.log(querySnapshot)
        querySnapshot.forEach(doc => {
          console.log(data)
          data = doc.data()
          data.id = doc.id
          picks.push(data)
        })
      })

    return picks
  },
  sendPasswordReset(email) {
    firebase.auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log('Password reset email sent')
      })
      .catch(error => {
        console.log(error)
      })
  },

  async addCreatePowerUps(userUID, dapperAccount, powerups, create) {
    let pudata = {}
    if (userUID !== '') {
      const fields = powerups
      fields.owner = userUID
      fields.dapperAccount = dapperAccount
      fields.modified = firebase.firestore.FieldValue.serverTimestamp()
      if (create) {
        fields.created = firebase.firestore.FieldValue.serverTimestamp()
      }
      await db.collection('ufcStrikePowerUps')
        .doc(userUID)
        .set(fields, {merge: true})
        .then(loadedpudata => {
          pudata = loadedpudata
        })
        .catch(error => {
          console.error('Error creating powerups: ', error)

          return false
        })
    }

    return pudata
  },

  async initOrResetMommaPowerUps(userUID, create) {
    console.log('reset momma powerups')
    let pudata = {}
    if (userUID !== '') {
      const fields = {}
      fields.owner = userUID
      fields.modified = firebase.firestore.FieldValue.serverTimestamp()
      fields.powerUps = [{
        'id': 0,
        'company': 'MoMMA',
        'mov': 'DEC',
        'tier': 'base',
        'name': '',
        'country': '',
        'status': false,
      }, {
        'id': 1,
        'company': 'MoMMA',
        'mov': 'SUB',
        'tier': 'base',
        'name': '',
        'country': '',
        'status': false,
      }, {
        'id': 4,
        'company': 'MoMMA',
        'mov': 'KO/TKO',
        'tier': 'base',
        'name': '',
        'country': '',
        'status': false,
      }]
      if (create) {
        fields.created = firebase.firestore.FieldValue.serverTimestamp()
      }
      await db.collection('mommaPowerUps')
        .doc(userUID)
        .set(fields)
        .then(loadedpudata => {
          pudata = loadedpudata
        })
        .catch(error => {
          console.error('Error creating powerups: ', error)

          return false
        })
    }

    return pudata
  },

  async updateMommaPowerUps(userUID, pfields) {
    let pudata = {}
    if (userUID !== '') {
      const fields = pfields
      fields.owner = userUID
      fields.modified = firebase.firestore.FieldValue.serverTimestamp()
      await db.collection('mommaPowerUps')
        .doc(userUID)
        .set(fields, {merge: false})
        .then(loadedpudata => {
          pudata = loadedpudata
        })
        .catch(error => {
          console.error('Error creating powerups: ', error)

          return false
        })
    }

    return pudata
  },

  async resetPowerUps(userUID) {
    let pudata = {}
    if (userUID !== '') {
      const fields = {}
      fields.owner = userUID
      fields.powerUps = []
      fields.powerUpsUsed = []
      fields.modified = firebase.firestore.FieldValue.serverTimestamp()
      await db.collection('ufcStrikePowerUps')
        .doc(userUID)
        .set(fields, {merge: true})
        .then(loadedpudata => {
          pudata = loadedpudata
        })
        .catch(error => {
          console.error('Error resetting powerups: ', error)

          return false
        })
      await db.collection('ufcStrikePowerUps')
        .doc(userUID)
        .set(fields, {merge: true})
        .then(loadedpudata => {
          pudata = loadedpudata
        })
        .catch(error => {
          console.error('Error resetting powerups: ', error)

          return false
        })
      await this.resetSolanaPowerUps(userUID)
      this.initOrResetMommaPowerUps(userUID, false)
    }

    return pudata
  },

  async resetSolanaPowerUps(userUID) {
    let solanaPowerups = []
    await db.collection('solanaPowerUps')
      .doc(userUID)
      .get()
      .then(async data => {
        if (data.exists) {
          data.data()
            .powerUps
            .forEach(nft => {
              if (nft?.status === 'used') {
                nft.status = ''
              }
              solanaPowerups.push(nft)
            })
        }
      })
      .then(async () => {
        const pfields = {powerUps: solanaPowerups}
        solFunctions.updateSolanaPowerUps(userUID, pfields)
      })
  },
  async getPowerUps(userUID) {
    let fields = {}
    if (userUID !== '') {
      await db.collection('ufcStrikePowerUps')
        .doc(userUID)
        .get()
        .then(doc => {
          fields = doc.data()
          if (fields) {
            fields.id = doc.id
          }
        })
        .catch(error => {
          console.log('Error getting PowerUps: ', error)
        })
    }

    return fields
  },
  tierMultiplier(tier) {
    let multiplier = 2
    if (tier === 'champion') {
      multiplier = 4
    } else if (tier === 'fandom') {
      multiplier = 3
    } else if (tier === 'rare') {
      multiplier = 3
    } else if (tier === 'epic') {
      multiplier = 3
    } else if (tier === 'uncommon') {
      multiplier = 2
    }

    return multiplier
  },
  async getFighterPowerUps(userUID, fighterId) {
    const powerUpCounts = {
      max: 16,
      striking: 0,
      grappling: 0,
      submissions: 0,
      power: 0,
      active: 0,
    }
    await this.getPowerUps(userUID)
      .then(powerups => {
        if (powerups?.powerUpsUsed) {
          for (let i = 0; i < powerups.powerUpsUsed.length; i += 1) {
            if (powerups.powerUpsUsed[i]?.careerFighter) {
              if (powerups.powerUpsUsed[i]?.fighter === fighterId) {
                if (powerups.powerUpsUsed[i].mov === 'DEC') {
                  if (powerups.powerUpsUsed[i].subMov === 'grappling') {
                    powerUpCounts.grappling += this.tierMultiplier(powerups.powerUpsUsed[i].tier)
                    powerUpCounts.active += powerUpCounts.grappling
                  } else if (powerups.powerUpsUsed[i].subMov === 'striking') {
                    powerUpCounts.striking += this.tierMultiplier(powerups.powerUpsUsed[i].tier)
                    powerUpCounts.active += powerUpCounts.striking
                  }
                } else if (powerups.powerUpsUsed[i].mov === 'SUB') {
                  powerUpCounts.submissions += this.tierMultiplier(powerups.powerUpsUsed[i].tier)
                  powerUpCounts.active += powerUpCounts.submissions
                } else if (powerups.powerUpsUsed[i].mov === 'KO/TKO') {
                  powerUpCounts.power += this.tierMultiplier(powerups.powerUpsUsed[i].tier)
                }
              }
              powerUpCounts.active = powerUpCounts.power + powerUpCounts.submissions + powerUpCounts.grappling + powerUpCounts.striking
            }
          }
        }
      })

    return powerUpCounts
  },
  async getPrizeList(uid, claimed) {
    const prizeClaims = []
    await db.collection('prizeClaims')
      .where('userUid', '==', uid)
      .where('claimed', '==', claimed)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const prize = doc.data()
          prize.uid = doc.id
          prize.id = doc.id
          if (prize.desc !== 'League Kitty' || prize.desc !== 'League Payment') {
            prizeClaims.push(prize)
          }
        })

        return prizeClaims
      })
      .catch(error => {
        console.log('Error getting claim data: ', error)
      })

    return prizeClaims
  },
  async removeClaim(userID, id) {
    await db.collection('prizeClaims')
      .where('userUid', '==', userID)
      .where('coachID', '==', id)
      .where('claimed', '==', false)
      .limit(1)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const prize = doc.data()
          prize.uid = doc.id
          prize.id = doc.id
          this.prizeClaimed(prize.id, {}, true)
        })

        return querySnapshot
      })
      .catch(error => {
        console.log('Error getting claim data: ', error)
      })
  },
  async getAchievement(achievementID) {
    let achievementData = {}
    await db.collection('achievements')
      .doc(achievementID)
      .get()
      .then(doc => {
        achievementData = doc.data()
      })
      .catch(error => {
        console.log('Error getting achievement: ', error)
      })

    return achievementData
  },

  async achievementRead(achievementID) {
    const fields = {
      read: true,
      readDate: firebase.firestore.FieldValue.serverTimestamp()
    }
    await db.collection('achievementsLog')
      .doc(achievementID)
      .update(fields)
      .catch(error => {
        console.log('Error saving claim: ', error)
      })
  },
  async getKittyLog(league) {
    const prizeClaims = []
    await db.collection('prizeClaims')
      .where('league', '==', league)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const prize = doc.data()
          prize.id = doc.id
          prizeClaims.push(prize)
        })

        return prizeClaims
      })
      .catch(error => {
        console.log('Error getting claim data: ', error)
      })

    return prizeClaims
  },
  async prizeClaimed(id, cfields, claimed) {
    const prizeClaims = []
    const fields = cfields
    if (claimed) {
      fields.claimedDate = firebase.firestore.FieldValue.serverTimestamp()
      fields.claimed = claimed
    } else {
      fields.created = firebase.firestore.FieldValue.serverTimestamp()
      fields.claimed = false
    }
    await db.collection('prizeClaims')
      .doc(id)
      .update(fields)
      .catch(error => {
        console.log('Error saving claim: ', error)
      })

    return prizeClaims
  },
  async getAllUserProfile() {
    return db.collection('profiles')
      .where('userUID', 'not-in', ['BT3O4g9kpDSVp9gyEvKXy3iabCk1', 'bIIBYNfazkbYPRl4uj8ERCm3VXj2'])
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting users: ', error)
      })
  },
  async getDiscordUsers() {
    return db.collection('discordUsers')
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting users: ', error)
      })
  },
  async getClaims() {
    const prizeClaims = []
    await db.collection('prizeClaims')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          prizeClaims.push(data)
        })
      })

    return prizeClaims
  },
  async addClaim(userUid, claimType, event, desc, silverCoins, goldCoins, rewardType, from, fromUid = null, league = null, claimed = false, achievementID = null, descLong = null, image = null, coachID = null) {
    const created = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('prizeClaims')
      .add({
        achievementID,
        userUid,
        claimType,
        event,
        desc,
        silverCoins,
        goldCoins,
        rewardType,
        created,
        from,
        fromUid,
        league,
        claimed,
        descLong,
        image,
        coachID,
      })
      .then(() => {
        console.log('claim added')
      })
      .catch(error => {
        console.error('Error creating team: ', error)
      })
  },
  async deleteClaim(id) {
    await db.collection('prizeClaims')
      .doc(id)
      .delete()

    return 'deleted'
  },
  async addTag(category, name) {
    const created = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('tags')
      .add({
        created,
        category,
        name,
      })
      .then(() => {
        console.log('news added')
      })
      .catch(error => {
        console.error('Error creating news: ', error)
      })
  },
  async getTags() {
    const tagList = []
    await db.collection('tags')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          tagList.push(data)
        })
      })

    return tagList
  },

  async addNews(news) {
    const newsData = news
    newsData.created = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('news')
      .add(newsData)
      .then(() => {
        console.log('news added')
      })
      .catch(error => {
        console.error('Error creating news: ', error)
      })
  },
  async updateNews(news, id) {
    const newsData = news
    newsData.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('news')
      .doc(id)
      .update(newsData)
      .catch(error => {
        console.log('Error updating news: ', error)
      })
  },
  async deleteNews(id) {
    await db.collection('news')
      .doc(id)
      .delete()

    return 'deleted'
  },
  async getNews() {
    const newsList = []
    await db.collection('news')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          newsList.push(data)
        })
      })

    return newsList
  },
  async addNotification(notif) {
    const notifData = notif
    notifData.created = firebase.firestore.FieldValue.serverTimestamp()
    if (!notifData.avatar) {
      notifData.avatar = 'icon-192x192.png'
    }

    /*
     notif: {
      avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
      title: 'Brunch this weekend?',
      subtitle: '<span class="text--primary">Ali Connors</span> &mdash; I\'ll be in your neighborhood doing errands this weekend. Do you want to hang out?',
      type: 'Marketplace',
      read: false,
      archived: true,
      from: 'uuid',
      to: 'uuid',
    },
     */
    await db.collection('notifications')
      .add(notifData)
      .then(() => {
        console.log('notification sent')
      })
      .catch(error => {
        console.error('Error creating notification: ', error)
      })
  },
  async getNotifications() {
    const newsList = []
    await db.collection('notifications')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          newsList.push(data)
        })
      })

    return newsList
  },
  async getMyNotification(linkedUid, subType) {
    const notif = []
    await db.collection('notifications')
      .where('linkedUid', '==', linkedUid)
      .where('subType', '==', subType)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          notif.push(data)
        })
      })
      .catch(error => {
        console.log('Error getting notif: ', error)
      })

    return notif
  },
  notifUpdate(id, notifData) {
    db.collection('notifications')
      .doc(id)
      .update(notifData)
      .catch(error => {
        console.log(error)
      })
  },

  // todo move to cloud function
  async saveLeaguePoints(ID, last, total) {
    const leagueData = {}
    leagueData.modified = firebase.firestore.FieldValue.serverTimestamp()
    leagueData.lastPoints = last
    leagueData.totalPoints = total
    await db.collection('leagues')
      .doc(ID)
      .update(leagueData)
      .then(() => {
        console.log('Save League points')
      })
      .catch(error => {
        console.log(error)
      })
  },

  // todo move to cloud function
  async setUFCEventStatusBlockTrading(fights) {
    const fighters = []
    for (let i = 0; i < fights.length; i += 1) {
      fighters.push(fights[i].fighter_1_id)
      fighters.push(fights[i].fighter_2_id)
    }
    for (let i = 0; i < fighters.length; i += 1) {
      db.collection('fighters')
        .where('fighter_id', '==', fighters[i])
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            console.log(doc.id)
            db.collection('fighters')
              .doc(doc.id)
              .update({status: 'pending-update'})
              .catch(error => {
                console.log(error)
              })
          })
        })
    }
  },
  async setPendingActive() {
    console.log('check pending')

    return db.collection('fighters')
      .where('fighter_trading_blocked', '==', true)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const fdata = doc.data()
          fdata.fighter_trading_blocked = false
          console.log(doc.id)
          this.updateFighter(doc.id, fdata)
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getFighterImages() {
    const fdataList = []
    await db.collection('fighterImages')
      .where('version', '==', '4')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const fdata = doc.data()
          fdata.uid = doc.id
          fdataList.push(fdata)
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })

    return fdataList
  },
  async deleteFighterImages(id) {
    await db.collection('fighterImages')
      .doc(id)
      .delete()
    console.log('deleted')
    console.log(id)

    return 'deleted'
  },

  async updateFighterImages(id, fields) {
    console.log('update')
    console.log(id)
    await db.collection('fighterImages')
      .doc(id)
      .update(fields)
      .then(() => {
        console.log('updated Fighter Image')
      })
      .catch(error => {
        console.log(error)
      })

    return 'updated'
  },

  // todo move to cloud function
  async updateAllMintedFighters() {
    console.log('dont sync selling, tokenID, owner, team')

    /*
    return db.collection('mintedFighters')
      .where('status', '==', 'active')
      .get()
      .then(querySnapshot => {
        console.log(querySnapshot)
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id
          if (data.purchase_price === 0) {
            data.purchase_price = data.cost
          }
          if (data.current_price === 0) {
            data.current_price = data.cost
          }
          console.log(data)
          console.log(data.slug)
          const fid = this.getBaseFighter(data.id)
          console.log(fid)
          db.collection('mintedFighters')
            .doc(data.id)
            .update(data)
            .catch(error => {
              console.log(error)

              return error
            })
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
     */
  },

  async updateStrikeNfts(userUid, dapperAddress, dapperAddressCreated, allFighters) {
    fcl.config({
      'discovery.wallet': 'https://accounts.meetdapper.com/fcl/authn-restricted',
      'discovery.wallet.method': 'POP/RPC',
      'accessNode.api': 'https://access-mainnet-beta.onflow.org',
      'app.detail.title': 'Masters of MMA',
      'app.detail.icon': 'https://mommagame.com/logo_small.png',
    })

    // get owned NFT metadata
    const idsResponse = await fcl.send([
      fcl.script(ufcStrikeNFTs),
      fcl.args([fcl.arg(dapperAddress, t.Address)]),
    ])
    const myuNFTs = await fcl.decode(idsResponse)
    let myNFTS = []
    let subMov = ''
    for (let i = 0; i < Object.entries(myuNFTs.items).length; i += 1) {
      const item = Object.entries(myuNFTs.items)[i][1]
      if (item.metadata?.TIER) {
        let movType = item.metadata['HIGHLIGHT CATEGORY'] || item.metadata['HIGHLIGHT TYPE']
        if (movType.toLowerCase() === 'submission' || movType.toLowerCase() === 'sub') {
          movType = 'SUB'
        } else if (movType.toLowerCase() === 'grappling' || movType.toLowerCase() === 'striking') {
          movType = 'DEC'
          subMov = (item.metadata['HIGHLIGHT CATEGORY'] || item.metadata['HIGHLIGHT TYPE']).toLowerCase()
        } else if (movType.toLowerCase() === 'ko/tko') {
          movType = 'KO/TKO'
        }
        let c = ''
        if (allFighters[item.metadata['ATHLETE NAME']]) {
          c = allFighters[item.metadata['ATHLETE NAME']]?.country
        }
        if (!allFighters[item.metadata['ATHLETE NAME']]) {
          console.log(`missing fighter ${item.metadata['ATHLETE NAME']}`)
        }
        if (!c) {
          console.log(`missing country ${item.metadata['ATHLETE NAME']}`)
        }

        myNFTS.push({
          index: i,
          name: item.metadata['ATHLETE NAME'],
          price: null,
          id: item.id,
          tier: item.metadata.TIER?.toLowerCase(),
          series: item.metadata.SERIES,
          mov: movType,
          subMov,
          status: '',
          country: c,
          company: 'Strike'
        })
      } else if (item?.seriesId === 4) {
        // hype card
        myNFTS.push({
          index: i,
          name: item.name,
          type: 'Hype',
          price: null,
          id: item.id,
          tier: '',
          series: '',
          mov: '',
          status: '',
          country: '',
          company: 'Strike'
        })
      }
    }
    myNFTS.sort((a, b) => a.name.localeCompare(b.name)) // order by name
    myNFTS = _.sortBy(myNFTS, 'tier') // put tier cards first
    let create = false
    if (!dapperAddressCreated) {
      create = true
    }
    let usedPowerUps = []
    let updatedPowerups = []
    if (dapperAddress !== '') {
      await db.collection('ufcStrikePowerUps')
        .doc(userUid)
        .get()
        .then(async data => {
          usedPowerUps = data.data()?.powerUpsUsed || data.data()?.powerUps || []
          if (usedPowerUps) {
            const usedPowerUpsDict = Object.assign({}, ...usedPowerUps.map(x => ({[x.id]: x})))

            // if already used replace 'used' status
            myNFTS.forEach(item => {
              const moment = item
              if (usedPowerUpsDict[item.id]) {
                moment.status = usedPowerUpsDict[item.id].status
                if (usedPowerUpsDict[item.id]?.careerFighter) {
                  moment.careerFighter = usedPowerUpsDict[item.id]?.careerFighter
                }
                if (usedPowerUpsDict[item.id]?.fighter) {
                  moment.fighter = usedPowerUpsDict[item.id]?.fighter
                }
                updatedPowerups.push(moment)
              } else {
                updatedPowerups.push(moment)
              }
            })
          } else {
            updatedPowerups = myNFTS
          }
          const powerups = {
            powerUps: myNFTS,
            powerUpsUsed: updatedPowerups,
          }
          const fields = powerups
          fields.owner = userUid
          fields.dapperAccount = dapperAddress
          fields.modified = firebase.firestore.FieldValue.serverTimestamp()
          if (create) {
            fields.created = firebase.firestore.FieldValue.serverTimestamp()
          }
          await db.collection('ufcStrikePowerUps')
            .doc(userUid)
            .set(fields, {merge: true})
            .catch(error => {
              console.error('Error creating powerups 2: ', error)

              return false
            })

          return usedPowerUps
        })
        .catch(error => {
          console.error('Error creating powerups 3: ', error)

          return false
        })
    }
  },
  async addMommaFighter(fighterId, fighter, creatorUid) {
    const fighterProps = fighter
    fighterProps.createdBy = creatorUid
    fighterProps.created = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('mommaFighters')
      .doc(fighterId)
      .set(fighter)
      .then(fighterData => {
        console.log('Momma Fighter Created')
        console.log(fighterData)

        return fighterData
      })
      .catch(error => {
        console.error('Error adding momma fighter: ', error)
      })
  },
  async editMommaFighter(fighterId, fields, modifiedBy) {
    const fighterProps = fields
    if (modifiedBy) {
      fighterProps.modifiedBy = modifiedBy
    }
    delete fighterProps.email
    delete fighterProps.ownerName
    fighterProps.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('mommaFighters')
      .doc(fighterId)
      .update(fighterProps)
      .then(fighterData => {
        console.log('Momma Fighter Edited')
        console.log(fighterData)

        return fighterData
      })
      .catch(error => {
        console.error('Error editing momma fighter: ', error)
      })
  },
  async mommaFighterUpdateXP(fighterId, skills) {
    console.log('start xp update')
    const sumSkills = skills.map(i => i.val)
      .reduce((a, b) => a + b)
    console.log(`new ${sumSkills / 10}`)
    const fields = {
      xp: sumSkills / 10,
    }
    await this.editMommaFighter(fighterId, fields)
  },
  async mommaFighterUpdateHealth(fighterId, health, addedHealth) {
    let newHealth = Number(health) + Number(addedHealth)
    if (newHealth > 100) {
      newHealth = 100
    }
    const fields = {health: newHealth}
    await this.editMommaFighter(fighterId, fields)
  },
  async mommaFighterSparUpdate(fighterId, deductHealth, skillName, skillGain, modifiedBy) {
    return db.collection('mommaFighters')
      .doc(fighterId)
      .get()
      .then(doc => {
        const {skills} = doc.data()
        const updatedFields = {
          skills,
          health: Math.round(Number(doc.data().health)),
          modifiedBy,
        }
        const skillNameIndex = skills.findIndex(i => i.id === skillName)
        console.log(skillName)
        console.log(skills[skillNameIndex])
        skills[skillNameIndex].val += skillGain
        updatedFields.skills = skills
        console.log('deduct health')
        console.log('old')
        console.log(doc.data().health)
        updatedFields.health -= deductHealth
        console.log('new')
        console.log(updatedFields.health)
        console.log(updatedFields)
        db.collection('mommaFighters')
          .doc(fighterId)
          .update(updatedFields)
          .then(fighterData => {
            console.log('Momma Fighter Edited')
            console.log(fighterData)

            return fighterData
          })
          .catch(error => {
            console.error('Error editing momma fighter: ', error)
          })
      })
      .catch(error => {
        console.log('Error getting fighter: ', error)
      })
  },
  async getMommaFighters() {
    return db.collection('mommaFighters')
      .where('published', '==', true)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getMommaFightersAdmin() {
    return db.collection('mommaFighters')
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getMommaFighter(fighterId) {
    return db.collection('mommaFighters')
      .doc(fighterId)
      .get()
      .then(doc => doc)
      .catch(error => {
        console.log('Error getting fighter: ', error)
      })
  },
  async getMyMommaFighters(userId) {
    return db.collection('mommaFighters')
      .where('owner', '==', userId)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error MoMMA Fighters: ', error)
      })
  },
  async add2DfighterImages(fields) {
    const fImages = fields
    fImages.created = firebase.firestore.FieldValue.serverTimestamp()
    fImages.type = '2D-AI-generated'
    fImages.used = false
    fImages.linkedFighterId = 0
    db.collection('fighterImages')
      .doc(fImages.id)
      .set(fImages)
      .then(data => data)
      .catch(error => {
        console.error('Error writing 2d Fighter images: ', error)
      })
  },
  async getGyms() {
    const gymList = []
    await db.collection('gyms')
      .where('status', '==', 'active')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          gymList.push(data)
        })
      })

    return gymList
  },
  async getGym(id) {
    return db.collection('gyms')
      .doc(id)
      .get()
      .then(doc => doc)
      .catch(error => {
        console.log('Error getting gym data: ', error)
      })
  },
  isLessThan24HourAgo(date) {
    const twentyFourHrInMs = 24 * 60 * 60 * 1000
    const twentyFourHoursAgo = Date.now() - twentyFourHrInMs

    return date > twentyFourHoursAgo
  },
  async initSparring(owner, fighter1, fighter2) {
    let autoAcceptSpar = false
    let accepted = firebase.firestore.FieldValue.serverTimestamp()
    let autoAccept = false
    let status = 'accepted'
    const fighter1Owner = owner
    const sparList = []

    return this.getMommaFighter(fighter2)
      .then(async fdata => {
        const fighter2data = fdata.data()
        const fighter2Owner = fighter2data.owner
        await db.collection('sparring')
          .where('fighter2', '==', fighter2)
          .get()
          .then(async querySnapshot => {
            let data = {}
            querySnapshot.forEach(doc => {
              data = doc.data()
              data.id = doc.id
              if (data?.accepted) {
                if (this.isLessThan24HourAgo(data.accepted.toDate())) {
                  autoAccept = true
                }
              } else {
                autoAccept = true
              }
              if (autoAccept) {
                sparList.push(data)
              }
            })
            if (sparList.length < 2) {
              autoAcceptSpar = true
              accepted = firebase.firestore.FieldValue.serverTimestamp()
              status = 'accepted'
            }
            await db.collection('sparring')
              .add({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                createdBy: owner,
                status,
                fighter1,
                fighter2,
                fighter1Owner,
                fighter2Owner,
                autoAcceptSpar,
                accepted,
              })
              .catch(error => {
                console.error('Error init sparring: ', error)
              })
              .then(() => {
                const fields = {
                  status: 'sparring',
                }
                this.editMommaFighter(fighter1, fields, owner)
                this.editMommaFighter(fighter2, fields, owner)
              })
              .catch(error => {
                console.error('Error init sparring: ', error)
              })
          })
      })
      .then(() => 'spar initiated')
  },
  relDiff(a, b) {
    return 100 * Math.abs((a - b) / ((a + b) / 2))
  },
  async startFight(sparId, fighter1, fighter2) {
    // todo move to serverless

    let fighter1Data = {}
    let fighter2Data = {}
    await this.getMommaFighter(fighter1)
      .then(f1Data => {
        fighter1Data = f1Data.data()
      })
    await this.getMommaFighter(fighter2)
      .then(f2Data => {
        fighter2Data = f2Data.data()
      })
    console.log('###1')
    await this.h2hResults(fighter1, fighter2, fighter1Data, fighter2Data)
      .then(async fieldsData => {
        console.log('###2')
        console.log(sparId)
        await this.updateSpar(sparId, fieldsData)
          .then(() => {
            console.log('start mfc add')
            this.addSparringProgress(sparId)
          })
      })
  },
  async updateSpar(sparId, fields) {
    const data = fields
    data.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('sparring')
      .doc(sparId)
      .update(data)
      .then(profile => {
        console.log(profile)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async getMySparQueue(owner) {
    const sparList = []
    await db.collection('sparring')
      .where('fighter1Owner', '==', owner)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          sparList.push(data)
        })
      })
    await db.collection('sparring')
      .where('fighter2Owner', '==', owner)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          sparList.push(data)
        })
      })

    return sparList
  },
  async getAllSparQueue(days = 1) {
    const sparList = []
    const daysToDate = new Date()
    daysToDate.setDate(daysToDate.getDate() - days)
    await db.collection('sparring')
      .where('created', '>', daysToDate)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          sparList.push(data)
        })
      })

    return sparList
  },

  async sparValidate(days = 1, fighter1or2, fighterId) {
    const daysToDate = new Date()
    let count = 0
    daysToDate.setDate(daysToDate.getDate() - days)
    await db.collection('sparring')
      .where('created', '>', daysToDate)
      .where(fighter1or2, '==', fighterId)
      .get()
      .then(querySnapshot => {
        count = querySnapshot.size
      })

    return count
  },
  async getAllSentPastSpars(owner, days = 90) {
    const sparList = []
    const daysToDate = new Date()
    daysToDate.setDate(daysToDate.getDate() - days)
    await db.collection('sparring')
      .where('fighter1Owner', '==', owner)
      .where('accepted', '>', daysToDate)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          sparList.push(data)
        })
      })

    return sparList
  },
  async getAllReceivedPastSpars(owner, days = 90) {
    const sparList = []
    const daysToDate = new Date()
    daysToDate.setDate(daysToDate.getDate() - days)
    await db.collection('sparring')
      .where('fighter2Owner', '==', owner)
      .where('accepted', '>', daysToDate)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          sparList.push(data)
        })
      })

    return sparList
  },
  async getSpar(id) {
    return db.collection('sparring')
      .doc(id)
      .get()
      .then(doc => doc)
      .catch(error => {
        console.log('Error getting spar data: ', error)
      })
  },
  async startTrainingCamps(owner, fighterId, gymId, campType, attributes, baseMMA) {
    console.log(attributes)
    const campStart = new Date()
    const startedTS = new Date()
    let ends = 604800 // 7s day in seconds
    let healthCost = 2
    if (campType === 'half') {
      ends = 345600 // 4 days in seconds
      healthCost = 1
    }
    const campEnd = new Date(startedTS.setSeconds(startedTS.getSeconds() + ends))

    return db.collection('trainingCamps')
      .add({
        created: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: owner,
        fighterId,
        gymId,
        baseMMA,
        start: campStart,
        end: campEnd,
        attributes,
        healthCost,
        campType,
        status: 'started',
      })
      .then(campData => {
        const fields = {
          gyms: {
            gymStatus: 'started',
            gymStart: campStart,
            gymEnd: campEnd,
            campType,
            baseMMA,
            attributes,
            healthCost,
            currentGymId: campData.id,
          },
        }
        this.editMommaFighter(fighterId, fields, owner)
      })
      .catch(error => {
        console.error('Error starting Gym: ', error)
      })
  },
  async getMyTrainingCamps(owner) {
    const trainingList = []

    return db.collection('trainingCamps')
      .where('createdBy', '==', owner)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          trainingList.push(data)
        })

        return trainingList
      })
  },
  async getMyTrainingCampsByFighterId(fighterId) {
    const trainingList = []

    return db.collection('trainingCamps')
      .where('fighterId', '==', fighterId)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          trainingList.push(data)
        })

        return trainingList
      })
  },

  updateTrainingCamps(campId, fields) {
    return db.collection('trainingCamps')
      .doc(campId)
      .set(fields, {merge: true})
      .catch(error => {
        console.log('Error updating camp', error)
      })
  },

  async getAllTrainingCamps() {
    const trainingList = []

    return db.collection('trainingCamps')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          trainingList.push(data)
        })

        return trainingList
      })
  },

  async h2hResults(fighter1, fighter2, fighter1Data, fighter2Data) {
    const fields = {}

    // fighter 1
    fields.maxAttribute1 = await this.getFighterSkills(fighter1)
    fields.xp1 = fighter1Data.xp
    fields.health1 = fighter1Data.health
    if (fighter1Data.injuries) {
      fields.injuries1 = fighter1Data.injuries
    }
    if (fighter1Data.strategy) {
      fields.strategy1 = fighter1Data.strategy
    } else {
      fields.strategy1 = {}
    }
    fields.xpBoosted1 = fighter1Data.xp
    await this.getFighterPowerUps(fighter1Data.owner, fighter1)
      .then(puData => {
        if (puData) {
          fields.powerUps1 = puData
          if (puData.active) {
            fields.xpBoosted1 = fighter1Data.xp + ((puData.active / 100) * fighter1Data.xp)
          }
        } else {
          fields.powerUps1 = {}
        }
      })

    // fighter 2
    fields.maxAttribute2 = await this.getFighterSkills(fighter2)
    fields.xp2 = fighter2Data.xp
    fields.health2 = fighter2Data.health
    if (fighter2Data.injuries) {
      fields.injuries2 = fighter2Data.injuries
    }
    if (fighter2Data?.strategy) {
      fields.strategy2 = fighter2Data.strategy
    } else {
      fields.strategy2 = {}
    }
    fields.xpBoosted2 = fighter2Data.xp
    await this.getFighterPowerUps(fighter2Data.owner, fighter2)
      .then(puData => {
        if (puData) {
          fields.powerUps2 = puData
          if (puData.active) {
            fields.xpBoosted2 = fighter2Data.xp + ((puData.active / 100) * fighter2Data.xp)
          }
        } else {
          fields.powerUps2 = {}
        }
      })

    fields.mainStrategy1 = null
    if (fields?.strategy1.length > 0) {
      const strategyTotal1 = fields.strategy1.map(i => i.val)
        .reduce((a, b) => a + b)
      if (strategyTotal1 > 0) {
        fields.xpBoosted1 += (((strategyTotal1 / 10) / 100) * fields.xpBoosted1)
        fields.mainStrategy1 = await this.getFighterMaxStrategy(fighter1)
      }
    }
    fields.mainStrategy2 = null
    console.log('fields.strategy2')
    console.log(fields.strategy2)
    if (fields.strategy2.length > 0) {
      const strategyTotal2 = fields.strategy2.map(i => i.val)
        .reduce((a, b) => a + b)
      if (strategyTotal2 > 0) {
        fields.xpBoosted2 += (((strategyTotal2 / 10) / 100) * fields.xpBoosted2)
        fields.mainStrategy2 = await this.getFighterMaxStrategy(fighter2)
      }
    }

    const dif = this.relDiff(fields.xpBoosted1, fields.xpBoosted2)
    let winnerId = ''
    let favourite = ''
    let underDog = ''
    if (fields.xpBoosted2 > fields.xpBoosted1) {
      favourite = 'Fighter2'
      underDog = 'Fighter1'
    } else {
      favourite = 'Fighter1'
      underDog = 'Fighter2'
    }
    let percentageWin = null
    let favStatus = ''
    let dogStatus = ''
    console.log('##########dif')
    console.log(dif)
    if (dif > 150) {
      percentageWin = 95
      favStatus = 'huge favourite'
      dogStatus = 'massive underdog'
    } else if (dif > 90) {
      percentageWin = 85
      favStatus = 'strong favourite'
      dogStatus = 'very clear underdog'
    } else if (dif > 50) {
      percentageWin = 75
      favStatus = 'strong favourite'
      dogStatus = 'notable underdog'
    } else if (dif > 20) {
      percentageWin = 60
      favStatus = 'slight favourite'
      dogStatus = 'slight underdog'
    } else if (dif > 10) {
      percentageWin = 55
      favStatus = 'very slight favourite'
      dogStatus = 'very slight underdog'
    } else {
      percentageWin = 50
      favStatus = 'very evenly matched'
      dogStatus = 'very evenly matched'
    }

    // winner before pu and strategy
    let winner = ''
    const chance = Math.random() * 100
    console.log('chance', chance)
    if (chance > percentageWin) {
      winner = underDog
      console.log('underdog won', winner)
    } else {
      winner = favourite
      console.log('favourite won', winner)
    }
    if (winner === 'Fighter1') {
      winnerId = fighter1
    } else if (winner === 'Fighter2') {
      winnerId = fighter2
    }

    let movResult = 'DEC'
    let mov = 'DEC'

    if (winner === 'Fighter1') {
      if (fields.mainStrategy1?.id) {
        if (fields.mainStrategy1.id === 'standnBang' || fields.mainStrategy1.id === 'distanceCounter') {
          mov = 'TKO/KO'
        } else if (fields.mainStrategy1.id === 'sub') {
          mov = 'SUB'
        }
      }
    } else if (winner === 'Fighter2') {
      if (fields.mainStrategy2?.id) {
        if (fields.mainStrategy2.id === 'standnBang' || fields.mainStrategy2.id === 'distanceCounter') {
          mov = 'TKO/KO'
        } else if (fields.mainStrategy2.id === 'sub') {
          mov = 'SUB'
        }
      }
    }

    console.log('######!!!!!!', mov, winner)

    let roundEnd = 3
    if (mov !== 'DEC') {
      const roundChance = Math.random() * 100
      if (roundChance > percentageWin) {
        if (Math.random() < 0.5) {
          roundEnd = 1
        } else {
          roundEnd = 2
        }
      } else if (Math.random() < 0.5) {
        roundEnd = 2
      }
    }
    console.log('chance', mov, chance, percentageWin)
    movResult = mov
    if (chance > percentageWin) {
      console.log(Math.random())
      if (Math.random() > 0.2) {
        movResult = mov
      } else {
        movResult = 'DEC'
      }
    }
    // add % chance of other movs

    // check pu and s to determine overall chance of winning
    const PU = null
    const strategy = null
    if (strategy) {
      console.log('strategy!')
    } else {
      console.log('no strat')
    }
    if (PU) {
      console.log('PUs!')
    } else {
      console.log('no Pus')
    }
    console.log(`${favourite} = favourite`)
    console.log(favStatus)
    console.log(dogStatus)
    console.log('############ round', roundEnd, `winner = ${winnerId}`, movResult)
    if (movResult === 'DEC') {
      roundEnd = 3
    }
    fields.accepted = firebase.firestore.FieldValue.serverTimestamp()
    fields.winner = winnerId
    fields.relativeDif = dif
    fields.favourite = favourite
    fields.underDog = underDog
    fields.favStatus = favStatus
    fields.dogStatus = dogStatus
    fields.roundEnd = roundEnd
    fields.movResult = movResult

    return fields
  },

  // Mfc Tournament Function
  async initMfcFight(owner, tournamentId, tType, round, fighter1, fighter2) {
    console.log('initMfcFight')
    let autoAcceptFight = false
    let accepted = firebase.firestore.FieldValue.serverTimestamp()
    let status = 'accepted'
    let fighter1data = {}
    await this.getMommaFighter(fighter1)
      .then(async fdata1 => {
        fighter1data = fdata1.data()
        if (!fighter1data?.nickname) {
          fighter1data.nickname = ''
        }
      })

    return this.getMommaFighter(fighter2)
      .then(async fdata2 => {
        const fighter2data = fdata2.data()
        if (!fighter2data?.nickname) {
          fighter2data.nickname = ''
        }
        const fighter1Owner = fighter1data.owner
        const fighter2Owner = fighter2data.owner

        return db.collection('mfcFight')
          .where('fighter2', '==', fighter2)
          .get()
          .then(async () => {
            autoAcceptFight = true
            accepted = firebase.firestore.FieldValue.serverTimestamp()
            status = 'scheduled'

            return db.collection('mfcFight')
              .add({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                createdBy: owner,
                tournamentId,
                tType,
                round,
                status,
                fighter1,
                fighter1Name: fighter1data.name,
                fighter1Base: fighter1data.mma_base,
                fighter1Country: fighter1data.country,
                fighter1NickName: fighter1data?.nickname,
                fighter1Record: {
                  w: fighter1data.win,
                  l: fighter1data.loss,
                  d: fighter1data.draw,
                },
                fighter2,
                fighter2Name: fighter2data.name,
                fighter2Base: fighter2data.mma_base,
                fighter2Country: fighter2data.country,
                fighter2NickName: fighter2data?.nickname,
                fighter2Record: {
                  w: fighter2data.win,
                  l: fighter2data.loss,
                  d: fighter2data.draw,
                },
                fighter1Owner,
                fighter2Owner,
                autoAcceptFight,
                accepted,
              })
              .catch(error => {
                console.error('Error init mfc status: ', error)
              })
              .then(fData => {
                const fields = {
                  mfcFightStatus: 'scheduled',
                }
                this.editMommaFighter(fighter1, fields, owner)
                this.editMommaFighter(fighter2, fields, owner)
                console.log(fData.id)

                return fData
              })
              .catch(error => {
                console.error('Error init mfc : ', error)
              })
          })
      })
  },
  async getFighterSkills(fighterID) {
    return this.getMommaFighter(fighterID)
      .then(fighter => {
        const {skills} = fighter.data()
        const getMax = arr => {
          const op = arr.reduce((acc, cur) => {
            const vals = Object.values(cur)
              .map(v => +v)
              .filter(v => v)

            return acc.concat(vals)
          }, [])

          const max = Math.max.apply(null, op)

          return op.indexOf(max)
        }

        return skills[getMax(skills)]
      })
  },
  async getFighterMaxStrategy(fighterID) {
    return this.getMommaFighter(fighterID)
      .then(fighter => {
        const {strategy} = fighter.data()
        const getMax = arr => {
          const op = arr.reduce((acc, cur) => {
            const vals = Object.values(cur)
              .map(v => +v)
              .filter(v => v)

            return acc.concat(vals)
          }, [])

          const max = Math.max.apply(null, op)

          return op.indexOf(max)
        }

        return strategy[getMax(strategy)]
      })
  },
  async startMFCFight(fightId, fighter1, fighter2) {
    let fighter1Data = {}
    let fighter2Data = {}
    await this.getMommaFighter(fighter1)
      .then(f1Data => {
        fighter1Data = f1Data.data()
      })
      .then(async () => {
        await this.getMommaFighter(fighter2)
          .then(f2Data => {
            fighter2Data = f2Data.data()
          })
      })
      .then(async () => {
        console.log('preh2')
        console.log(fighter1Data)
        const fields = await this.h2hResults(fighter1, fighter2, fighter1Data, fighter2Data)
        console.log('got{h2h}')
        console.log(fields)
        fields.status = 'started'
        await this.updateMFCFight(fightId, fields)
      })
  },
  async updateMFCFight(fightId, fields) {
    const data = fields
    data.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('mfcFight')
      .doc(fightId)
      .update(data)
      .then(profile => profile)
      .catch(error => {
        console.log(error)
      })
  },
  async getMyMFCFightQueue(owner) {
    const fightList = []
    await db.collection('mfcFight')
      .where('fighter1Owner', '==', owner)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          fightList.push(data)
        })
      })
    await db.collection('mfcFight')
      .where('fighter2Owner', '==', owner)
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          fightList.push(data)
        })
      })

    return fightList
  },
  async getAllMFCFightQueue() {
    const fightList = []
    await db.collection('mfcFight')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          fightList.push(data)
        })
      })

    return fightList
  },
  async MFCFight(id) {
    return db.collection('mfcFight')
      .doc(id)
      .get()
      .then(doc => doc)
      .catch(error => {
        console.log('Error getting spar data: ', error)
      })
  },
  async MFCFightSummary(id) {
    return db.collection('mfcFightSummary')
      .doc(id)
      .get()
      .then(doc => doc)
      .catch(error => {
        console.log('Error getting spar data: ', error)
      })
  },
  async updateMFCFightSummary(id, fields) {
    return db.collection('mfcFightSummary')
      .doc(id)
      .update(fields)
      .then(doc => doc)
      .catch(error => {
        console.log('Error getting spar data: ', error)
      })
  },
  async getAllMFCFightSummaries() {
    const fightList = []
    await db.collection('mfcFightSummary')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          fightList.push(data)
        })
      })

    return fightList
  },
  async creatMFCFightSummary(fightID, tType, round) {

    return this.MFCFight(fightID)
      .then(async doc => {
        const fightData = doc.data()
        const fighter1Id = fightData.fighter1
        const fighter2Id = fightData.fighter2
        const {winner} = fightData

        console.log('create summary, round:', round, winner)

        // todo
        const totalRounds = 3
        const {roundEnd} = fightData

        function randomIntFromInterval(min, max) {
          return Math.random() * (max - min + 1) + min
        }

        const minute = Math.floor(randomIntFromInterval(1, 5))
        let second = 0
        if (minute < 5) {
          second = Math.floor(randomIntFromInterval(3, 55))
        }
        const zeroPad = num => String(num)
          .padStart(2, '0')
        const roundTime = `${minute}:${zeroPad(second)}`
        console.log('start intro')
        console.log(fightData)
        let team1 = 'team1'
        let team2 = 'team2'
        let intro1 = ''
        let intro2 = ''
        let intro3 = ''

        const regionNames = new Intl.DisplayNames(['en'], {type: 'region'})
        await this.getTeam(fightData.fighter1Owner)
          .then(team1Data => {
            if (team1Data.name) {
              team1 = team1Data?.name
            }
          })
        await this.getTeam(fightData.fighter2Owner)
          .then(team2Data => {
            if (team2Data?.name) {
              team2 = team2Data.name
            }
          })
        let f1debut = false
        const wldRecord1 = fightData.fighter1Record.w + fightData.fighter1Record.l + fightData.fighter1Record.d
        if (wldRecord1 === 0) {
          f1debut = true
        }
        let wins1 = `${fightData.fighter1Record.w} win`
        if (fightData.fighter1Record.w > 1) {
          wins1 = `${fightData.fighter1Record.w} wins`
        }
        let lose1 = `${fightData.fighter1Record.l} lose`
        if (fightData.fighter1Record.l > 1) {
          lose1 = `${fightData.fighter1Record.l} losses`
        }
        let name1 = fightData.fighter1Name
        if (fightData.fighter1NickName) {
          name1 = `
        '${fightData.fighter1NickName}', ${fightData.fighter1Name}`
        }
        let f1record = `with a record of ${wins1} & ${lose1} representing for '${team1}', ${name1}!`
        if (f1debut) {
          f1record = `debuting for '${team1}'`
        }
        let f2debut = false
        const wldRecord2 = fightData.fighter2Record.w + fightData.fighter2Record.l + fightData.fighter2Record.d
        if (wldRecord2 === 0) {
          f2debut = true
        }
        let wins2 = `${fightData.fighter2Record.w} win`
        if (fightData.fighter2Record.w > 1) {
          wins2 = `${fightData.fighter2Record.w} wins`
        }
        let lose2 = `${fightData.fighter2Record.l} lose`
        if (fightData.fighter2Record.l > 1) {
          lose2 = `${fightData.fighter2Record.l} losses`
        }
        let name2 = fightData.fighter2Name
        if (fightData.fighter2NickName) {
          name2 = `
        '${fightData.fighter2NickName}', ${fightData.fighter2Name}`
        }
        let f2record = `with a record of ${wins2} & ${lose2} representing for team '${team2}', ${name2}`
        if (f2debut) {
          f2record = `debuting for team '${team2}'`
        }
        intro1 = `Welcome to ${tType}.`
        intro2 = `In the red corner hailing from ${regionNames.of(fightData.fighter1Country)}, a ${fightData.fighter1Base} fighter ${f1record}, ${name1}!!`
        intro3 = `And in the blue corner from ${regionNames.of(fightData.fighter2Country)}, a ${fightData.fighter2Base} fighter ${f2record}, ${name2}!!`

        const options = [1, 2, 3, 4, 5, 6]
        const startOptions = options.slice()
        const midOptions = options.slice()
        const endOptions = options.slice()

        function getPlayByPlayOption(selectedOptions) {
          const optionItem = Math.floor(Math.random() * selectedOptions.length)
          options.splice(optionItem, 1)

          return optionItem
        }

        let round1 = {}
        let round2 = {}
        let round3 = {}
        let round4 = {}
        let round5 = {}

        // todo refactor loop/switch

        round1 = {
          start: getPlayByPlayOption(startOptions),
          mid1: getPlayByPlayOption(midOptions),
          mid2: getPlayByPlayOption(midOptions),
          mid3: getPlayByPlayOption(midOptions),
          mid4: getPlayByPlayOption(midOptions),
        }
        if (roundEnd > 1) {
          round2 = {
            start: getPlayByPlayOption(startOptions),
            mid1: getPlayByPlayOption(midOptions),
            mid2: getPlayByPlayOption(midOptions),
            mid3: getPlayByPlayOption(midOptions),
            mid4: getPlayByPlayOption(midOptions),
          }
        }
        if (roundEnd > 2) {
          round3 = {
            start: getPlayByPlayOption(startOptions),
            mid1: getPlayByPlayOption(midOptions),
            mid2: getPlayByPlayOption(midOptions),
            mid3: getPlayByPlayOption(midOptions),
            mid4: getPlayByPlayOption(midOptions),
          }
        }
        if (roundEnd > 3) {
          round3 = {
            start: getPlayByPlayOption(startOptions),
            mid1: getPlayByPlayOption(midOptions),
            mid2: getPlayByPlayOption(midOptions),
            mid3: getPlayByPlayOption(midOptions),
            mid4: getPlayByPlayOption(midOptions),
          }
        }
        if (roundEnd > 4) {
          round4 = {
            start: getPlayByPlayOption(startOptions),
            mid1: getPlayByPlayOption(midOptions),
            mid2: getPlayByPlayOption(midOptions),
            mid3: getPlayByPlayOption(midOptions),
            mid4: getPlayByPlayOption(midOptions),
          }
        }
        if (roundEnd > 5) {
          round5 = {
            start: getPlayByPlayOption(startOptions),
            mid1: getPlayByPlayOption(midOptions),
            mid2: getPlayByPlayOption(midOptions),
            mid3: getPlayByPlayOption(midOptions),
            mid4: getPlayByPlayOption(midOptions),
          }
        }

        const finalRound = getPlayByPlayOption(endOptions)

        if (roundEnd === 1) {
          round1.finalRound = finalRound
        } else if (roundEnd === 2) {
          round2.finalRound = finalRound
        } else if (roundEnd === 3) {
          round3.finalRound = finalRound
        } else if (roundEnd === 4) {
          round4.finalRound = finalRound
        } else if (roundEnd === 5) {
          round5.finalRound = finalRound
        }

        return db.collection('mfcFightSummary')
          .doc(fightID)
          .set({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            fighter1Id,
            fighter2Id,
            winner,
            totalRounds,
            roundEnd,
            roundTime,
            movResult: fightData.movResult,
            intro1,
            intro2,
            intro3,
            round1,
            round2,
            round3,
            round4,
            round5,
          })
          .then(() => winner)
          .catch(error => {
            console.error('Error writing league: ', error)
          })
      })
  },

  // endMfc Tournament Function
  async checkSpins(userUID) {
    let spinsData = {}
    await db.collection('wheelSpinsAllowed')
      .doc(userUID)
      .get()
      .then(doc => {
        spinsData = doc.data()
      })
      .catch(error => {
        console.log('Error getting wheel spins allowed', error)
      })

    return spinsData
  },
  deductSpin(userId, wtype) {
    db.collection('wheelSpinsAllowed')
      .doc(userId)
      .get()
      .then(doc => {
        const spinData = doc.data()
        if (wtype === 'premium') {
          spinData.premiumSpins -= 1
        } else if (wtype === 'standard') {
          spinData.standardSpins -= 1
        }
        spinData.lastDeducted = firebase.firestore.FieldValue.serverTimestamp()
        db.collection('wheelSpinsAllowed')
          .doc(doc.id)
          .update(spinData)
          .catch(error => {
            console.log(error)
          })
      })
  },
  addSpin(userId, wtype) {
    db.collection('wheelSpinsAllowed')
      .doc(userId)
      .get()
      .then(doc => {
        if (doc.exists) {
          const spinData = doc.data()
          if (wtype === 'premium') {
            spinData.premiumSpins += 1
          } else if (wtype === 'standard') {
            spinData.standardSpins += 1
          }
          spinData.lastAdded = firebase.firestore.FieldValue.serverTimestamp()
          db.collection('wheelSpinsAllowed')
            .doc(doc.id)
            .update(spinData)
            .catch(error => {
              console.log(error)
            })
        } else {
          const spinData = {}
          if (wtype === 'premium') {
            spinData.premiumSpins = 1
            spinData.standardSpins = 0
          } else if (wtype === 'standard') {
            spinData.standardSpins = 1
            spinData.premiumSpins = 0
          }
          spinData.lastAdded = firebase.firestore.FieldValue.serverTimestamp()
          db.collection('wheelSpinsAllowed')
            .doc(userId)
            .set(spinData)
            .catch(error => {
              console.log(error)
            })
        }
      })
  },
  getAllSpins() {
    const spins = []

    return db.collection('wheelSpinsAllowed')
      .get()
      .then(querySnapshot => {
        let data = {}
        querySnapshot.forEach(doc => {
          data = doc.data()
          data.id = doc.id
          spins.push(data)
        })

        return spins
      })
  },
  wheelPrizeLog(userId, data, wheelType) {
    console.log('Save win log')
    const created = firebase.firestore.FieldValue.serverTimestamp()
    db.collection('wheelSpinLog')
      .doc()
      .set({
        created,
        user: userId,
        wheel: wheelType,
        prizeType: data.prizeType,
        prizeQty: data.prizeQty,
        description: data.description,
        probability: data.probability,
        claimed: false,
      })
      .catch(error => {
        console.error('Error creating prize log: ', error)
      })
  },
  wheelPrizeLogClaimed(id) {
    console.log('Save win log as claimed')
    const claimedDate = firebase.firestore.FieldValue.serverTimestamp()
    db.collection('wheelSpinLog')
      .doc(id)
      .update({
        claimedDate,
        claimed: true,
      })
      .catch(error => {
        console.error('Error updating claimed ', error)
      })
  },
  async deleteWheelPrizeLog(id) {
    console.log('delete')
    console.log(id)
    await db.collection('wheelSpinLog')
      .doc(id)
      .delete()

    return 'deleted'
  },

  userWheelPrizeLog(userId) {
    console.log('Read win log')

    return db.collection('wheelSpinLog')
      .where('user', '==', userId)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
      .catch(error => {
        console.error('Error creating team: ', error)
      })
  },
  allWheelPrizeLog(claimed) {
    console.log('Read win log')

    return db.collection('wheelSpinLog')
      .where('claimed', '==', claimed)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
      .catch(error => {
        console.error('Error creating team: ', error)
      })
  },

  async getUserAdvisors(userId) {
    let aData = []
    await db.collection('mintedAdvisors')
      .doc(userId)
      .get()
      .then(doc => {
        aData = doc.data()
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
      .catch(error => {
        console.error('Error getting advisors: ', error)
      })

    return aData
  },
  async advisorsLookup(Id) {
    let aData = []
    await db.collection('advisors')
      .doc(Id)
      .get()
      .then(doc => {
        aData = doc.data()
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
      .catch(error => {
        console.error('Error getting advisor data: ', error)
      })

    return aData
  },
  async hireAdvisor(userID, advisorList) {
    const advisorListData = {}
    advisorListData.advisors = advisorList
    advisorListData.modified = firebase.firestore.FieldValue.serverTimestamp()
    await db.collection('mintedAdvisors')
      .doc(userID)
      .set(advisorListData)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
      .catch(error => {
        console.error('Error getting advisor data: ', error)
      })

    return advisorListData
  },

  getAllMintedAdvisors() {
    return db.collection('mintedAdvisors')
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting advisors: ', error)
      })
  },
  async getmfcTournaments(status) {
    return db.collection('mfcTournaments')
      .where('status', '==', status)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting mfc tournaments: ', error)
      })
  },
  async createTournament(tData) {
    const tournamentData = tData
    tournamentData.created = firebase.firestore.FieldValue.serverTimestamp()
    console.log(tournamentData)

    return db.collection('mfcTournaments')
      .doc(tournamentData.start)
      .set(tournamentData)
      .then(data => data)
      .catch(error => {
        console.error('Error writing league: ', error)
      })
  },

  async createUpdateTournament(tData) {
    if (tData.start) {
      const tournamentData = tData
      if (!tournamentData.created) {
        tournamentData.created = firebase.firestore.FieldValue.serverTimestamp()
      }
      tournamentData.modified = firebase.firestore.FieldValue.serverTimestamp()
      console.log(tournamentData)

      return db.collection('mfcTournaments')
        .doc(tournamentData.start)
        .set(tournamentData)
        .then(data => data)
        .catch(error => {
          console.error('Error writing league: ', error)
        })
    }
    console.log('no start')

    return 'no start'
  },
  getMFCtournamentByID(id) {
    return db.collection('mfcTournaments')
      .doc(id)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async deductBooster(userId, fighterId, boosterOld, boosterType) {
    const fields = boosterOld
    fields[boosterType] -= 1

    return db.collection('boosters')
      .doc(userId)
      .set(fields)
      .then(data => data)
      .catch(error => {
        console.error('Error writing boosters: ', error)
      })
  },
  async addBooster(userId, boosterType, qty) {
    return db.collection('boosters')
      .doc(userId)
      .get()
      .then(data => {
        let fields = data.data()
        if (data.exists) {
          console.log(fields)
        } else {
          fields = {
            health: 0,
            camp: 0,
          }
        }
        if (fields[boosterType]) {
          fields[boosterType] += qty
        } else {
          fields[boosterType] = qty
        }

        return db.collection('boosters')
          .doc(userId)
          .set(fields)
          .then(bdata => {
            console.log(bdata)
            let logo = require('@/assets/images/careermode/misc/speed_camp.png')
            let desc = 'You\'ve received an accelerated training camp booster.'
            let title = 'Accelerated camp booster received'
            if (boosterType === 'health') {
              logo = require('@/assets/images/careermode/misc/speed_health.png')
              title = 'Health booster received'
              desc = 'You\'ve received a health booster (5 pack).'
            }
            const notif = {
              avatar: logo,
              title,
              subtitle: desc,
              type: 'Booster',
              subType: `${boosterType}`,
              read: false,
              archived: false,
              metaData: {
                status: 'Accepted', // accepted, denied
                link: '/my-fighters',
              },
              updated: '', // date
              fromName: 'MoMMA',
              to: userId,
            }
            this.addNotification(notif)
          })
          .catch(error => {
            console.error('Error writing boosters: ', error)
          })
      })
      .catch(error => {
        console.error('Error writing boosters: ', error)
      })
  },
  saveMFCProgress(id, added, attribute, fighterId, opponentId, source, value) {
    db.collection('mfcProgress')
      .doc(id)
      .set({
        added,
        attribute,
        fighterId,
        opponentId,
        source,
        value,
      })
      .then(() => {
        console.log('mfcProgress successfully created!')
      })
      .catch(error => {
        console.error('Error mfcProgress profile: ', error)
      })
  },
  addSparringProgress(fightId) {
    console.log('get spar')
    this.getSpar(fightId)
      .then(data => {
        console.log(data.data())
        const added = data.data().accepted
        let wValue = 0
        let lValue = 0
        let wAttribute = ''
        let lAttribute = ''
        const source = 'sparring'

        if (data.data().winner === data.data().fighter1 && data.id) {
          wAttribute = data.data().maxAttribute2.id
          wValue = 2

          // winner
          const wId = `${data.id}-${data.data().fighter1}-${wAttribute}`
          this.saveMFCProgress(wId, added, wAttribute, data.data().fighter1, data.data().fighter2, source, wValue,)

          // loser
          lAttribute = data.data().maxAttribute1.id
          lValue = 1
          const lId = `${data.id}-${data.data().fighter2}-${lAttribute}`
          this.saveMFCProgress(lId, added, lAttribute, data.data().fighter2, data.data().fighter1, source, lValue,)
        } else {
          console.log('phantom spar')
          console.log(data.id)
        }
      })
  },
  getSparringProgress(fighter, fighterId) {
    return db.collection('sparring')
      .where(fighter, '==', fighterId)
      .orderBy('accepted', 'desc')
      .get()
      .then(querySnapshot => {
        let result = false
        let added = ''
        let attribute = ''
        let value = 0
        const source = 'sparring'
        querySnapshot.forEach(data => {
          added = data.data().accepted
          if (data.data().winner === fighterId) {
            attribute = data.data().maxAttribute2.id
            value = 2
            result = true
          } else if (data.data().fighter2 === fighterId) {
            if (data.data().maxAttribute1?.id) {
              attribute = data.data().maxAttribute1.id
              value = 1
              result = true
            } else if (this.admin) {
              console.log('phantom spar')
              console.log(data.id)
            }
          }
          let opponentId = 0
          if (data.data().fighter2 === fighterId) {
            opponentId = data.data().fighter1
          } else if (data.data().fighter1 === fighterId) {
            opponentId = data.data().fighter2
          }
          const id = `${data.id}-${fighterId}-${attribute}`
          if (result) {
            db.collection('mfcProgress')
              .doc(id)
              .set({
                added,
                attribute,
                fighterId,
                opponentId,
                source,
                value,
              })
              .then(() => {
                console.log('mfcProgress successfully created!')
              })
              .catch(error => {
                console.error('Error mfcProgress profile: ', error)
              })
          }
        })
      })
      .catch(error => {
        console.log('Error getting mfcProgress documents: ', error)
      })
  },
  getAllTrainingProgress(fighterId) {
    return db.collection('mfcProgress')
      .where('fighterId', '==', fighterId)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.error('Error getting: ', error)
      })
  },
  deleteMFCprogress(id) {
    return db.collection('mfcProgress')
      .doc(id)
      .delete()
      .then(() => {
        console.log(`deleted ${id}`)
      })
      .catch(error => {
        console.error('Error getting: ', error)
      })
  },
  async getTrainingProgress(fighterId) {
    await db.collection('trainingCamps')
      .where('fighterId', '==', fighterId)
      .where('status', '==', 'completed')
      .get()
      .then(querySnapshot => {
        let added = ''
        const source = 'training camp'
        querySnapshot.forEach(data => {
          added = data.data().completed
          Object.entries(data.data().attributes)
            .forEach(f => {
              const id = `${data.id}-${fighterId}-${f[0]}`
              db.collection('mfcProgress')
                .doc(id)
                .set({
                  added,
                  attribute: f[0],
                  fighterId,
                  source,
                  value: f[1],
                })
                .then(() => {
                  console.log('mfcProgress successfully created!')
                })
                .catch(error => {
                  console.error('Error mfcProgress profile: ', error)
                })
            })
        })
      })
      .catch(error => {
        console.log('Error getting mfcProgress documents: ', error)
      })
  },
  getMfcProgress(fighterId) {
    return db.collection('mfcProgress')
      .where('fighterId', '==', fighterId)
      .orderBy('added', 'desc')
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },
  async getBase(fighterId) {
    const fighter = {
      striking: 0,
      wrestling: 0,
      grappling: 0,
      submissions: 0,
      cardio: 0,
      power: 0,
      resilience: 0,
      fightiq: 0,
    }

    function getLowest(id, val, attr) {
      fighter[attr] = val
      if ((id === attr) && (val > 0)) {
        if ((fighter[attr] > val) || (fighter[attr] === 0)) {
          fighter[attr] = val
        }
      }
    }

    await db.collection('sparring')
      .where('fighter1', '==', fighterId)
      .where('attributesIssued', '==', true)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(async s => {
          if (s.data()?.maxAttribute1) {
            Object.entries(fighter)
              .forEach(async f => {
                await getLowest(s.data().maxAttribute1.id, s.data().maxAttribute1.val, f[0])
              })
          }
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
    await db.collection('sparring')
      .where('fighter2', '==', fighterId)
      .where('attributesIssued', '==', true)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(async s => {
          if (s.data()?.maxAttribute2) {
            Object.entries(fighter)
              .forEach(f => {
                getLowest(s.data().maxAttribute2.id, s.data().maxAttribute2.val, f[0])
              })
          }
        })
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })

    return this.getMommaFighter(fighterId)
      .then(fighterData => {
        const skills = []
        fighterData.data()
          .skills
          .forEach(s => {
            skills[s.id] = s.val
          })

        fighterData.data()
          .skills
          .map(a => a.id)
          .forEach(f => {
            if (fighter[f] === 0) {
              fighter[f] = skills[f]
            }
          })

        return fighter
      })
  },
  checkDiscordUser(discordId) {
    return db.collection('discordUsers')
      .doc(discordId)
      .get()
      .then(querySnapshot => querySnapshot)
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  },

  async linkDiscord(discordUid, user) {
    return db.collection('discordUsers')
      .doc(discordUid)
      .update({
        userId: user.uid,
        modified: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(profile => {
        console.log(profile)
        const fields = {
          discordId: discordUid,
        }
        this.updateUsersProfile(user, fields)
      })
      .catch(error => {
        console.log(error)
      })
  },

  async removeDiscord(discordUid, user) {
    return db.collection('discordUsers')
      .doc(discordUid)
      .update({userId: ''})
      .then(() => {
        const fields = {
          discordId: '',
        }
        if (user) {
          this.updateUsersProfile(user, fields)
        }
      })
      .catch(error => {
        console.log(error)
      })
  },

  async savePastLeaderBoards(eventId, eventName, global, blockasset, strike) {
    console.log('start save leaderboard')
    const fields = {
      eventId: eventId,
      eventName: eventName,
      global: global,
      blockasset: blockasset,
      strike: strike,
      modified: firebase.firestore.FieldValue.serverTimestamp()
    }
    return db.collection('pastLeaderBoards')
      .doc(eventId)
      .set(fields)
      .catch(error => {
        console.log(error)
      })

  }
}
